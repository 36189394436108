import React from 'react'

import Layout from '../components/layout'
import backgroundImg from '../img/city-never-sleeps.jpg'
import gladlyLogo from '../img/logo/gladly-logo.png'

const IndexPage = () => {
  // '#c76e0d' // old page orange
  const orangeColor = 'orange'
  const highlightWordsStyle = {
    color: orangeColor,
    whiteSpace: 'nowrap',
  }
  return (
    <Layout>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
          position: 'absolute',
        }}
      >
        <div
          style={{
            backgroundImage: `url('${backgroundImg}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 120px inset',
            filter: 'saturate(.6) brightness(.45)',
            zIndex: -1,
          }}
        />
        <img
          src={gladlyLogo}
          style={{
            position: 'absolute',
            top: 20,
            left: 20,
            width: 70,
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              color: '#FFF',
              marginBottom: 80,
              maxWidth: 700,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h1 style={{ margin: 10 }}>
              We believe advertising can{' '}
              <span style={highlightWordsStyle}>do good</span> and{' '}
              <span style={highlightWordsStyle}>be better</span>.
            </h1>
            <h4 style={{ maxWidth: 400 }}>
              That's why we built Tab for a Cause, which gives ad revenue to
              charity as you surf the web.
            </h4>
            <a
              href="https://tab.gladly.io"
              style={{
                margin: 8,
                padding: 20,
                background: orangeColor,
                borderRadius: 3,
                boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 5px',
                textDecoration: 'none',
                color: '#FFF',
                textTransform: 'uppercase',
                fontWeight: 600,
              }}
            >
              Visit Tab for a Cause
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
