import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import faviconImg from '../img/logo/gladly-favicon.png'
import metaImg from '../img/city-never-sleeps.jpg'
import './layout.css'

const metaTitle = 'Gladly'
const metaDescription = 'We believe advertising can do good and be better.'
const metaDomain = 'gladly.io'
const twitterHandle = '@TabForACause'

const adScript = `
googletag.cmd.push(function() {
  if (googletag.pubads().isInitialLoadDisabled()) {
    googletag.display("div-gpt-ad-1614955491295-0");
    googletag.refresh("div-gpt-ad-1614955491295-0");
  } else {
    googletag.display("div-gpt-ad-1614955491295-0");
  }
});
`

const footerLinkStyle = {
  textDecoration: 'none',
  color: 'rgba(255, 255, 255, 0.8)',
  margin: '0px 20px',
  fontSize: 12,
}

// Use flexbox to make sure the footer sticks to the bottom of the page.
const Layout = ({ children }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100vw',
      height: '100vh',
    }}
  >
    <div
      style={{
        flex: '1 0 auto',
      }}
    >
      <Helmet title={'Gladly'}>
        <link rel="icon" href={faviconImg} />,
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="gladly, ads, advertising, tab for a cause, tab"
        />
        <meta name="og:type" content="website" />
        <meta name="og:title" content={metaTitle} />
        <meta name="og:description" content={metaDescription} />
        <meta name="og:image" content={metaImg} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:site" content={twitterHandle} />
        <meta name="twitter:creator" content={twitterHandle} />
        <meta name="twitter:image" content={metaImg} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:domain" content={metaDomain} />
        <script
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        />
        <script>
          {`
          window.googletag = window.googletag || {cmd: []};
          googletag.cmd.push(function() {
            googletag.defineSlot('/18190176,43865596/MCM_Validation', [1, 1], 'div-gpt-ad-1614955491295-0').addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
          });
          `}
        </script>
      </Helmet>
      <div
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        {children}
      </div>
    </div>
    <div
      style={{
        flexShrink: 0,
        display: 'flex',
        padding: '20px 6px',
        zIndex: 1,
      }}
    >
      <a style={footerLinkStyle} href="https://tab.gladly.io/contact/">
        Contact
      </a>
      <a style={footerLinkStyle} href="https://tab.gladly.io/terms/">
        Terms
      </a>
      <a style={footerLinkStyle} href="https://tab.gladly.io/privacy/">
        Privacy
      </a>
      <a style={footerLinkStyle} href="https://tab.gladly.io/team/">
        Team
      </a>
      <a style={footerLinkStyle} href="https://tab.gladly.io/jobs/">
        Jobs
      </a>
    </div>

    <div id="div-gpt-ad-1614955491295-0">
      <script dangerouslySetInnerHTML={{ __html: adScript }} />
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
}

export default Layout
